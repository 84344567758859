import axios from 'axios'
import axiosBetterStacktrace from 'axios-better-stacktrace'
import { userState } from '../services/User/userState'
import { runAsyncWithRetries } from './promises'
import { getAmplitudeDeviceId } from '../services/Analytics/platforms/amplitude'

const axiosClient = axios.create()

// Without this change axios truncates the stack trace of the error
// see: https://github.com/axios/axios/issues/2387
axiosBetterStacktrace(axiosClient)

export class MixtilesAxiosClient {
  get(url, config) {
    return runAsyncWithRetries({
      opName: `GET request - ${url}`,
      op: () => axiosClient.get(this._getUrl(url), this._getConfig(config)),
      shouldAbortRetry: this.shouldAbortRetry,
    })
  }

  post(url, data, config) {
    return runAsyncWithRetries({
      opName: `POST request - ${url}`,
      op: () =>
        axiosClient.post(this._getUrl(url), data, this._getConfig(config)),
      shouldAbortRetry: this.shouldAbortRetry,
    })
  }

  put(url, data, config) {
    return runAsyncWithRetries({
      opName: `PUT request - ${url}`,
      op: () =>
        axiosClient.put(this._getUrl(url), data, this._getConfig(config)),
      shouldAbortRetry: this.shouldAbortRetry,
    })
  }

  delete(url, config) {
    return runAsyncWithRetries({
      opName: `DELETE request - ${url}`,
      op: () => axiosClient.delete(this._getUrl(url), this._getConfig(config)),
      shouldAbortRetry: this.shouldAbortRetry,
    })
  }

  _getAuthHeader() {
    const { sessionToken } = userState.getUser() || {}
    return sessionToken ? { sessionToken } : {}
  }

  _getVersionMetadataHeaders() {
    return window.KEYS.buildCommit
      ? { appVersion: window.KEYS.buildCommit }
      : {}
  }

  _getConfig({ headers, ...config } = {}) {
    return {
      headers: {
        ...this._getAuthHeader(),
        ...this._getVersionMetadataHeaders(),
        amplitudeDeviceId: getAmplitudeDeviceId(),
        ...headers,
      },
      ...config,
    }
  }

  _getUrl(url) {
    return window.KEYS.apiServerUrl + url
  }

  shouldAbortRetry(error) {
    if (!error?.response?.status) {
      return false
    }
    return error.response.status >= 400 && error.response.status < 500
  }
}

export const mixtilesAxios = new MixtilesAxiosClient()

const ServerURLs = {
  MarketingConsentGiven: 'v4/updateMarketingConsent',
  UserAttributions: 'v4/userAttributions',
  StoreAttributions: 'v4/storeAttribution',
  CaptureUserData: 'v1/user/userCapturedData',
  GdprConsentGiven: 'v4/updateConsent',
}

export async function getUserAttributions() {
  try {
    const { data } = await mixtilesAxios.get(ServerURLs.UserAttributions)
    return data || {}
  } catch (error) {
    console.error('Failed to fetch user attributions from server', error)
    return {}
  }
}

export async function marketingConsentGiven() {
  return mixtilesAxios.post(ServerURLs.MarketingConsentGiven, {
    didConsent: true,
  })
}

export async function capturedUserData(data) {
  return mixtilesAxios.post(ServerURLs.CaptureUserData, data)
}

export function extractApiError(error) {
  if (
    error &&
    typeof error === 'object' &&
    error.response &&
    error.response.data
  ) {
    const { data } = error.response
    if (typeof data === 'object') {
      return data
    }
  }
}

// extractErrorMessage enables extracting the proper error message from error thrown by function calling the api.
// based on: https://axios-http.com/docs/handling_errors.
export function extractErrorMessage(error) {
  const data = extractApiError(error)
  if (data) {
    if (data.error) {
      return `${error.message} - ${data.error}`
    } else if (data.message) {
      return `${error.message} - ${data.message}`
    }
  }

  return error?.message || error
}
